<template>
  <v-container class="content mt-0 pa-0 elevation-0" fluid>
    <v-row align="center" class="mx-auto elevation-0">
      <!-- start: week days -->
      <v-col cols="12" md="12" class="border-top p-0 d-flex align-center justify-center">
        <v-btn text icon class="mx-0" @click="week--">
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>

        <v-slide-x-transition>
          <v-btn-toggle class="btn-toggle-days p-1 px-2 px align-center justify-center" v-model="form.date"
            active-class="primary white--text" mandatory>
            <v-btn v-for="(day, index) in weekPlaces" :key="index" class="days-btn mx-0"
              :input-value="day.date === form.date" :value="day.date">
              <div>
                <strong>{{
                  $moment(day.date, 'DD.MM.YYYY').format('dddd') + ' ' + $moment(day.date, 'DD.MM.YYYY').format('DD.MM')
                  }}</strong>
              </div>
              <div>
                <small>{{ day.persons }} {{ $t('generic.lang_places') }}</small>
              </div>
              <div>
                <small class="font-weight-bold"> <span class="success--text"> {{ day.freeKontingent }} </span>
                  <span>{{ $t('generic.lang_available') }}</span></small>
              </div>
            </v-btn>
          </v-btn-toggle>
        </v-slide-x-transition>

        <v-btn text icon class="mx-0" @click="week++">
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-col>

      <v-col col="12" class="m-0 p-0 white mt-2">
        <v-row class="ma-0">
          <v-col cols="12" lg="4" md="6" sm="12">
            <b-form-select :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']" v-model="form.type"
              :label-field="$t('generic.lang_allTypes')" :placeholder="$t('generic.lang_allTypes')" size="md">
              <b-form-select-option :value="-1">{{
                $t('generic.lang_allTypes')
                }}
              </b-form-select-option>
              <b-form-select-option :value="0">{{
                $t('eventbee.lang_entryTicket')
                }}
              </b-form-select-option>
              <b-form-select-option :value="1">{{
                $t('eventbee.lang_eventTicket')
                }}
              </b-form-select-option>
            </b-form-select>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <b-form-select :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']" v-model="form.groupID"
              :label-field="$t('eventbee.lang_eventGroup')" :placeholder="$t('eventbee.lang_eventGroup')" size="md">
              <b-form-select-option :value="-1">{{
                $t('generic.lang_all') + ' ' + $t('eventbee.lang_eventGroups')
                }}
              </b-form-select-option>
              <b-form-select-option v-for="t in this.eventGroups" :key="t.id" :value="t.id">
                {{ t.name }}
              </b-form-select-option>
            </b-form-select>
          </v-col>

          <v-col cols="12" lg="3" md="6" sm="12">
            <b-form-select :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']" v-model="form.providerID"
              :placeholder="$t('generic.lang_provider')" se size="md">
              <b-form-select-option :value="-1">{{
                $t('generic.lang_all') + ' ' + $t('generic.lang_provider')
                }}
              </b-form-select-option>
              <b-form-select-option v-for="t in this.serviceProviders" :key="t.userID" :value="t.userID">
                {{ t.fullName }}
              </b-form-select-option>
            </b-form-select>
          </v-col>
          <v-col cols="12" lg="1" md="6" sm="12">
            <v-btn color="warning" class="ma-0" block depressed @click="printPDFTodayOrders">
              <v-icon>
                mdi-receipt
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- start: orders -->

    <v-row class="mx-0">
      <v-col v-if="this.loadingOrders" cols="12" class="p-3 text-center">
        <v-progress-circular indeterminate color="primary" class="mx-auto"></v-progress-circular>
      </v-col>
      <v-col v-else-if="!this.loadingOrders && (orders || []).length === 0" cols="12" class="p-3 text-center">
        <v-alert type="info" class="mx-auto" dense text>
          {{
          $t('eventbee.lang_norOrderFound').replace('$date$', this.form.date)
          }}
        </v-alert>
      </v-col>
      <v-col v-for="(order, orderIndex) in orders" cols="12" class="border-bottom border-left px-0" :key="order.uuid">
        <v-row role="v-list-item" class="mx-0 px-0" @click="setOrder($event, order)" v-ripple>
          <!-- start: event title -->
          <v-col cols="12" md="3" class="pl-0">
            <h6 :style="{
                backgroundColor: eventPlan(order).color,
              }" :class="{
                primary: !eventPlan(order).color,
              }" class="white--text p-1 py-2 rounded-tr-xl rounded-br-xl">
              {{ order.order.eventName }} <br>{{ tables(order.order)? ` ${$t('generic.lang_table')}
              ${tables(order.order)}` :'' }}
            </h6>
          </v-col>
          <!-- end: event title -->

          <!-- start: order details  -->
          <v-col cols="12" md="6" class="">
            <div class="py-1">
              <font-awesome-icon :icon="['fal', 'user-alt']" class="primary--text" style="font-size: 20px" />
              <span class="pl-2">
                {{ order.order.numberOfPersons }}
                {{ $t('generic.lang_persons') }}</span>
            </div>

            <div class="py-1">
              <font-awesome-icon :icon="['fal', 'calendar-day']" class="primary--text" style="font-size: 20px" />
              <span class="pl-2">
                {{
                $moment(order.order.created, 'DD.MM.YYYY HH:mm').format(
                'dddd, DD. MMMM YYYY, HH:MM'
                )
                }}</span>
            </div>
            <div v-if="order.order.slotFrom || order.order.slotTo || order.order.arrival">
              <font-awesome-icon :icon="['fal', 'clock']" class="primary--text" style="font-size: 20px" />
              <span v-if="order.order.arrival" class="text--black ml-1 font-weight-bold">
                {{ order.order.arrival }}
              </span>
              <span v-else class="text--black ml-1 font-weight-bold">{{
                (order.order.slotFrom ? order.order.slotFrom : '') + ' ' + (order.order.slotTo ? order.order.slotTo :
                '')
                }}</span>
            </div>
            <div class="py-1" v-if="order.order.attribute">
              <font-awesome-icon :icon="['fal', 'tag']" class="primary--text" style="font-size: 20px" />
              <span class="text--black ml-1 font-weight-bold">
                {{ attribute(order.order.attribute) }}
              </span>
            </div>

            <div class="py-1" v-if="order.order.customerData.first_name || order.order.customerData.last_name">
              {{ $t('eventbee.lang_booked_by') }}
              <span class="pl-2 font-weight-bold text-underline">
                {{
                (order.order.customerData.first_name || '') +
                ' ' +
                (order.order.customerData.last_name || '')
                }}</span>
            </div>

            <div class="py-1" v-if="order.order.orderNote">
              <v-alert border="left" colored-border color="info" elevation="0">
                {{ order.order.orderNote }}
              </v-alert>
            </div>
            <div style="z-index: 3">
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ $t('eventbee.lang_tickets') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <li style="min-width: 150px" v-for="(ticket, i) in order.order.tickets" :key="i"
                        class="list-unstyled">
                        <strong>{{ ticket.qty + 'x ' }}</strong> {{ ticket.name }}
                        <strong v-if="ticket.table">{{ ticket.table ? $t('generic.lang_table') + ' ' + ticket.table : ''
                          }}</strong>
                        <strong v-if="ticket.rowNumber">{{
                          $t('eventbee.lang_row') + '-' + ticket.rowNumber + ',' + $t('eventbee.lang_seat') + ' ' +
                          ticket.seatNumber }}</strong>
                      </li>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-col>
          <!-- end: order details  -->

          <v-col cols="12" md="3">
            {{ guestExpectedAfterOrBefore(order) }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- html2pdf -->
    <vue-html2pdf :paginate-elements-by-height="1100" :pdf-quality="2" :preview-modal="true" :enable-download="true"
      :show-layout="false" :float-layout="true" :filename="$t('generic.lang_orders')+'_'+this.form.date" pdf-format="a4"
      pdf-orientation="portrait" pdf-content-width="100%" ref="html2Pdf">
      <section slot="pdf-content" class="w-100 white">
        <v-row class="mx-0">
          <v-col :key="order.uuid" v-for="order in orders" cols="12" class="border-bottom border-left px-0">
            <v-row class="mx-0 px-0">
              <!-- start: event title -->
              <v-col cols="12" md="3" class="pl-0">
                <h6 :style="{
                backgroundColor: eventPlan(order).color,
              }" :class="{
                primary: !eventPlan(order).color,
              }" class="white--text p-1 py-2 rounded-tr-xl rounded-br-xl">
                  {{ order.order.eventName }}
                </h6>
              </v-col>
              <!-- end: event title -->

              <!-- start: order details  -->
              <v-col cols="12" md="6" class="">
                <div class="py-1">
                  <font-awesome-icon :icon="['fal', 'user-alt']" class="primary--text" style="font-size: 20px" />
                  <span class="pl-2">
                    {{ order.order.numberOfPersons }}
                    {{ $t('generic.lang_persons') }}</span>
                </div>

                <div class="py-1">
                  <font-awesome-icon :icon="['fal', 'calendar-day']" class="primary--text" style="font-size: 20px" />
                  <span class="pl-2">
                    {{
                    $moment(order.order.created, 'DD.MM.YYYY HH:mm').format(
                    'dddd, DD. MMMM YYYY, HH:MM'
                    )
                    }}</span>
                </div>
                <div v-if="order.order.slotFrom || order.order.slotTo || order.order.arrival">
                  <font-awesome-icon :icon="['fal', 'clock']" class="primary--text" style="font-size: 20px" />
                  <span v-if="order.order.arrival" class="text--black ml-1 font-weight-bold">
                    {{ order.order.arrival }}
                  </span>
                  <span v-else class="text--black ml-1 font-weight-bold">{{
                    (order.order.slotFrom ? order.order.slotFrom : '') + ' ' + (order.order.slotTo ? order.order.slotTo
                    : '')
                    }}</span>
                </div>
                <div class="py-1" v-if="order.order.attribute">
                  <font-awesome-icon :icon="['fal', 'tag']" class="primary--text" style="font-size: 20px" />
                  <span class="text--black ml-1 font-weight-bold">
                    {{ attribute(order.order.attribute) }}
                  </span>
                </div>

                <div class="py-1">
                  {{ $t('eventbee.lang_booked_by') }}
                  <span class="pl-2 font-weight-bold text-underline">
                    {{
                    order.order.customerData.first_name +
                    ' ' +
                    order.order.customerData.last_name
                    }}</span>
                </div>

                <div class="py-1" v-if="order.order.orderNote">
                  <v-alert colored-border color="info" elevation="0">
                    {{ order.order.orderNote }}
                  </v-alert>
                </div>
              </v-col>
              <!-- end: order details  -->

              <v-col cols="12" md="3">
                {{ guestExpectedAfterOrBefore(order) }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </section>
    </vue-html2pdf>
    <EventbeeOrderDetailsComponent v-model="showOrderDetailsDialog" :uuid="selectedOrderUUID"
      @updateOrders="getOrders" />
  </v-container>
</template>

<script>
import {ENDPOINTS} from '@/config';
import {Events} from '@/plugins/events';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar,
  faList,
  faUserAlt,
  faCalendarDay, faTag
} from '@fortawesome/pro-light-svg-icons';
import EventbeeOrderDetailsComponent from "./EventbeeOrderDetailsComponent";
import VueHtml2pdf from "vue-html2pdf";

library.add(faCalendar, faList, faUserAlt, faCalendarDay, faTag);
export default {
  name: 'OrdersOverviewList',
  components: {
    EventbeeOrderDetailsComponent,
    'font-awesome-icon': FontAwesomeIcon,
    'vue-html2pdf': VueHtml2pdf,
  },
  data() {
    return {
      week: 0,
      showType: 0,
      showOrderDetailsDialog: false,
      selectedOrderUUID: null,
      eventGroups: [],
      serviceProviders: [],
      weekPlaces: [],
      orders: [],
      loadingOrders: false,
      form: {
        type: -1,
        groupID: -1,
        providerID: -1,
        date: this.$moment().format('DD.MM.YYYY').toLowerCase(),
      },
    };
  },
  watch: {
    form: {
      handler() {
        this.getOrders();
      },
      deep: true,
      immediate: true,
    },
    week(v) {
      this.getWeekPlaces();
    }
  },
  computed: {
    attribute(){
      return (attribute)=>{
        return attribute.replaceAll('<br>', ', ');
      }
    },
    guestExpectedAfterOrBefore() {
      return (order) => {
        if (
            order.event?.eventType === 0 &&
            order?.order?.slotFrom &&
            order?.order?.slotTo
        ) {
          const fullDate = this.$moment(
              `${order?.order?.date} ${order.order.slotFrom}`,
              'DD.MM.YYYY HH:mm'
          );

          return this.$moment(fullDate).fromNow();
        } else if (order.event?.eventType === 0 && order?.order?.arrival) {
          const fullDate = this.$moment(
              `${order?.order?.date} ${order.order.arrival}`,
              'DD.MM.YYYY HH:mm'
          );

          return this.$moment(fullDate).fromNow();
        } else {
          const fullDate = this.$moment(
              `${order?.order?.date} ${order.order.fromTime}`,
              'DD.MM.YYYY HH:mm'
          );

          return this.$moment(fullDate).fromNow();
        }
      };
    },
    eventPlan() {
      return ((order) => {
        return order.eventPlan || {};
      })
    }
  },
  methods: {
    tables(order){
      return Array.from(new Set(order.tickets.filter(i=> i.table).map(i=>i.table))).toLocaleString()
    },
    printPDFTodayOrders() {
      this.$refs.html2Pdf.generatePdf()
    },
    setOrder(e, order) {
      if(e.target.className.includes('expansion') || e.target.parentElement?.className.includes('expansion')){
        e.preventDefault();
        return;
      }
      this.selectedOrderUUID = order.order.uuid;
      this.showOrderDetailsDialog = true;
    },
    getEventGroups() {
      this.loadingGroups = true;
      this.axios
          .post(ENDPOINTS.EVENTBEE.SETTINGS.EVENTGROUPS.GET, {})
          .then((res) => {
            this.eventGroups = res.data.data;
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: err.message,
              color: 'error',
            });
          })
          .finally(() => {
            this.loadingGroups = false;
          });
    },
    getServiceProviders() {
      this.loadingGroups = true;
      this.axios
          .post(ENDPOINTS.EVENTBEE.SERVICEPROVIDERS, {})
          .then((res) => {
            this.serviceProviders = res.data.users;
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: err.message,
              color: 'error',
            });
          })
          .finally(() => {
            this.loadingGroups = false;
          });
    },
    getWeekPlaces() {
      this.axios
          .post(ENDPOINTS.EVENTBEE.ORDERS.OVERVIEW.WEEKLY_PLACES, {
            week: this.week,
          })
          .then((res) => {
            if (res.data.status === 'success') {
              this.weekPlaces = res.data.data;
            } else {
              throw new Error(this.$t('generic.lang_errorOccurred'));
            }
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: err.message,
              color: 'error',
            });
          })
          .finally(() => {
            this.loadingGroups = false;
          });
    },
    getOrders() {
      this.loadingOrders = true;
      const _form = {...this.form};
      _form.date = this.$moment(_form.date, 'DD.MM.YYYY').format('YYYY-MM-DD');

      this.axios
          .post(ENDPOINTS.EVENTBEE.ORDERS.OVERVIEW.GET, _form)
          .then((res) => {
            if (res.data.status === 'success') {
              this.orders = res.data.data
              this.isExpanded = this.orders.map(()=> false);
            } else {
              throw new Error(this.$t('generic.lang_errorOccurred'));
            }
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: err.message,
              color: 'error',
            });
          })
          .finally(() => {
            this.loadingOrders = false;
          });
    },
  },
  mounted() {
    this.getWeekPlaces();
    this.getEventGroups();
    this.getServiceProviders();
  },
};
</script>

<style>
.days-btn .v-btn__content {
  display: block !important;
}

.days-btn {
  width: calc(100% / 7) !important;
}

.btn-toggle-days {
  width: 100% !important;
}

@media only screen and (max-width: 1200px) {
  .days-btn {
    width: calc(100% / 7) !important;
    font-size: 12px !important;
  }

  .btn-toggle-days {
    width: 90% !important;
  }
}
</style>
